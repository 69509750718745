import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import { Props, SingleDish } from './categoryList';

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  flex-grow: 1;
`;
const Container = styled.div`
  flex-grow: 1;
  width: 90%;
  height: 100%;
  display: flex;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.olive};
  color: ${({ theme }) => theme.colors.brown};

  ${({ theme }) => theme.media.tablet} {
    width: 90%;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    padding: 60px;
    width: 70%;
  }
`;
const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3.5rem;
    width: 50%;
    align-self: center;
    justify-content: center;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    display: flex;
    align-items: center;
    font-size: 5rem;
  } ;
`;

const Description = styled.article`
  font-size: 1.7rem;
  text-align: center;
  display: flex;
  margin: 20px 5px;
  color: ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3rem;
    align-self: center;
    width: 60%;
    margin: 40px 0;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    font-size: 4.5rem;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    align-items: center;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.brown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3rem;
    width: 90%;
    align-self: center;
    align-items: flex-start;
    flex-grow: 1;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    font-size: 5rem;
    justify-content: space-between;
  } ;
`;

// const Image = styled.img`
//   width: 100%;
//   ${({ theme }) => theme.media.tablet} {
//     width: 50%;
//     margin: auto;
//   }
// `;

const colorLabel = (label: any) => {
  switch (label) {
    case 'Ostry':
      return true;
    case 'Ostra':
      return true;
    case 'Ostre':
      return true;
    default:
      return false;
  }
};

const Label = styled.span<LabelProps>`
  text-align: center;
  min-width: 120px;
  padding: 5px;
  font-size: 1.4rem;
  margin: 15px 0;
  align-self: flex-start;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ label, theme }) =>
    // eslint-disable-next-line no-constant-condition
    colorLabel(label) ? theme.colors.darkbrown : theme.colors.darkgreen};
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    font-size: 3rem;
    margin-left: 50px;
    padding: 5px 20px;
    border-radius: 30px;
    align-self: flex-start;
  } ;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column;
  ${({ theme }) => theme.media.UHDDesktop} {
    flex-grow: 2;
    justify-content: center;
  }
`;

const Weight = styled.span`
  align-self: flex-end;
  text-align: center;
  font-size: 1.6rem;
  ${({ theme }) => theme.media.desktop} {
    font-size: 2.2rem;
  }
  ${({ theme }) => theme.media.UHDDesktop} {
    font-size: 3rem;
  } ;
`;
const Price = styled.span`
  align-self: flex-end;
`;

const StyledLink = styled(Link)`
  align-self: center;
`;
const Button = styled.button`
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  font-size: 1.4rem;
  min-width: 120px;
  align-self: center;
  margin-top: 30px;
  padding: 10px;
  font-family: 'Oregano';
  border: 0;
  border-radius: 20px;
  transition: all 0.2s linear;
  background-color: ${({ theme }) => theme.colors.button};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    font-size: 1.2rem;
  }
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.8rem;
    padding: 8px;
    &:hover {
      color: ${({ theme }) => theme.colors.darkorange};
      font-size: 1.6rem;
    }
  }
  ${({ theme }) => theme.media.desktop} {
    width: 257px;
    height: 70px;
    font-size: 2.2rem;
    padding: 10px 15px;
    &:hover {
      font-size: 2rem;
    }
  }
`;
export type LabelProps = {
  label?: string;
};

const Dish = ({ data, location }: Props) => {
  const { edges } = data.allContentfulDishes;
  const logo = data.allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    data.allContentfulImages.edges[0].node.backgrounds[0].file.url;

  return (
    <Layout
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      title="Danie"
      description="Zamów już dziś na wynos"
      ogTitle="Oferta | Smaki Azji Siemianowice Śląskie"
      ogContent="Zorganizuj swoją imprezę właśnie u nas, odwiedź nas ma miejscu ! Smaki Azji Siemianowice Śląskie ul.Śląska 39"
    >
      {edges &&
        // eslint-disable-next-line array-callback-return
        edges.map((edge: SingleDish, key) => {
          const {
            category,
            slug,
            // heroImage,
            title,
            description,
            price,
            label,
            weight,
          } = edge.node;
          if (`/menu/${category}/${slug}` === location.pathname) {
            return (
              <ContentContainer key={key}>
                <Container key={key}>
                  <DescriptionContainer>
                    <Title>{title}</Title>
                    <Description>
                      {description && description.description}
                    </Description>
                  </DescriptionContainer>
                  <InfoContainer>
                    <Weight>
                      Porcja: <br /> {weight}
                    </Weight>
                    <Price>{price}zł</Price>
                  </InfoContainer>
                  {label && <Label label={label}>{label}</Label>}
                  <StyledLink to={`/menu/${category}#${slug}`}>
                    <Button type="button">Wróć do menu</Button>
                  </StyledLink>
                </Container>
              </ContentContainer>
            );
          }
          return null;
        })}
    </Layout>
  );
};

export default Dish;

export const dishesQuery = graphql`
  query getDishes {
    allContentfulDishes(sort: { fields: slug, order: ASC }) {
      edges {
        node {
          title
          description {
            description
          }
          price
          slug
          category
          label
          weight
        }
      }
    }
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
